const burgerModalFunction = () => {
  const burgerButton = document.querySelector('.burger_menu_button');
  const closeBurgerButton = document.querySelector('.close_burger_menu_button');
  const burgerModal = document.querySelector('.burger_modal');

  burgerButton.addEventListener('click', function () {
    burgerModal.classList.add('active_burger');
  });

  closeBurgerButton.addEventListener('click', function () {
    burgerModal.classList.remove('active_burger');
  });
};
document.addEventListener('DOMContentLoaded', async () => {
  burgerModalFunction();
});

